// Modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Services
import { AuthGuardBuyerService } from '../auth/auth-guard-buyer-service';

// Components
import { BuyerHomeComponent } from './buyer-home/buyer-home.component';
import { FullOrderComponent } from './order-history/full-order/full-order.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { CartComponent } from './cart/cart.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { ItemsListComponent } from './catalog/items-list/items-list.component';
import { PastPurchaseItemsComponent } from './past-purchase/past-purchase-items/past-purchase-items.component';
import { NotificationItemsComponent } from './notifications/notification-items/notification-items.component';
import { LandingPageComponent } from '../shared/landing-page/landing-page.component';
import { UserProfileComponent } from '../user/user-profile/user-profile.component';
import { OutletsComponent } from './outlets/outlets.component';
import { OutletItemComponent } from './outlets/outlet-item/outlet-item.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { AuctionItemsComponent } from './auctions/auction-items/auction-items.component';
import { MyBidsComponent } from './auctions/my-bids/my-bids.component';
import { AuctionDetailsComponent } from './auctions/auction-details/auction-details.component';
import { CanDeactivateCartGuard } from '../auth/canDeactivate-cart-guard';
import { UserFiltersComponent } from './user-filters/user-filters.component';
import { environment } from '../../environments/environment';
import { CartAbandonmentComponent } from './cart/cart-abandonment/cart-abandonment.component';
import { BuyersOffersComponent } from './buyers-offers/buyers-offers.component';
import { CompactOrderResolve } from './order-history/orders/compact-order.resolve';

export const routes: Routes = [
  {
    path: 'buyer',
    component: BuyerHomeComponent,
    children: [
      {
        path: 'thank-you/:orderId',
        component: ThankYouComponent,
        canActivate: [AuthGuardBuyerService]
      },
      {
        path: 'full-order/:docEntry',
        component: FullOrderComponent,
        resolve: {
          order: CompactOrderResolve
        },
        canActivate: [AuthGuardBuyerService]
      },
      {
        path: 'just_sold',
        component: NotificationItemsComponent
      },
      {
        path: 'live_offers',
        loadComponent: () => import('./buyers-offers/buyers-offers.component').then(c => c.BuyersOffersComponent)
      },
      {
        path: 'live_offers/:liveOfferId',
        loadComponent: () => import('./buyers-offers/buyers-offers.component').then(c => c.BuyersOffersComponent)
      },
      {
        path: 'price-drop',
        component: ItemsListComponent
      },
      {
        path: 'just_launched',
        component: ItemsListComponent
      },
      {
        path: environment.currentDeal.url,
        component: ItemsListComponent
      },
      {
        path: 'home',
        component: LandingPageComponent
      },
      {
        path: 'past_purchases',
        component: PastPurchaseItemsComponent,
        canActivate: [AuthGuardBuyerService]
      },
      {
        path: 'split/:docEntry',
        redirectTo: '/user/split/:docEntry'
      },
      {
        path: 'user_filters/:id',
        component: UserFiltersComponent
      },
      {
        path: 'items/:code',
        component: ItemsListComponent
      },
      {
        path: '**',
        component: ItemsListComponent
      },
      {
        path: '',
        redirectTo: 'buyer/home',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'notifications',
    component: NotificationCenterComponent,
    canActivate: [AuthGuardBuyerService]
  },
  {
    path: 'user/profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardBuyerService]
  },
  {
    path: 'cart',
    component: CartComponent,
    canActivate: [AuthGuardBuyerService],
    canDeactivate: [CanDeactivateCartGuard]
  },
  {
    path: 'cart/:cartId',
    component: CartAbandonmentComponent,
    canActivate: [AuthGuardBuyerService],
  },
  {
    path: '',
    redirectTo: '/guest/home',
    pathMatch: 'full'
  },
  {
    path: 'outlets',
    component: OutletsComponent
  },
  {
    path: 'outlets/:outletName',
    component: OutletItemComponent,
    children: [
      {
        path: 'price_drop',
        component: OutletItemComponent
      },
      {
        path: 'just_launched',
        component: OutletItemComponent
      },
      {
        path: environment.currentDeal.url,
        component: OutletItemComponent
      },
    ],
  },
  {
    path: 'auctions',
    component: AuctionsComponent,
    children: [
      {
        path: '',
        component: AuctionItemsComponent,
        pathMatch: 'full'
      },
      {
        path: 'my_bids',
        component: MyBidsComponent,
        children: [
          {
            path: '',
            component: AuctionItemsComponent,
          },
          {
            path: 'winning',
            component: AuctionItemsComponent,
          },
          {
            path: 'not_winning',
            component: AuctionItemsComponent,
          },
          {
            path: 'won',
            component: AuctionItemsComponent,
          },
          {
            path: 'watching',
            component: AuctionItemsComponent,
          },
        ]
      },
      {
        path: 'details/:id',
        component: AuctionDetailsComponent,
      },
    ]
  },

  // GUEST
  {
    path: 'guest/notifications',
    component: NotificationCenterComponent
  },
  {
    path: 'guest/auctions',
    component: AuctionsComponent,
    children: [
      {
        path: '',
        component: AuctionItemsComponent,
        pathMatch: 'full'
      },
      {
        path: 'details/:id',
        component: AuctionDetailsComponent,
      },
      {
        path: 'my_bids',
        component: MyBidsComponent,
        children: [
          {
            path: '',
            component: AuctionItemsComponent,
          },
          {
            path: 'winning',
            component: AuctionItemsComponent,
          },
          {
            path: 'not_winning',
            component: AuctionItemsComponent,
          },
          {
            path: 'won',
            component: AuctionItemsComponent,
          },
          {
            path: 'watching',
            component: AuctionItemsComponent,
          },
        ]
      },
    ]
  },
  {
    path: 'guest/outlets',
    component: OutletsComponent
  },
  {
    path: 'guest/outlets/:outletName',
    component: OutletItemComponent,
    children: [
      {
        path: 'price_drop',
        component: OutletItemComponent
      },
      {
        path: 'just_launched',
        component: OutletItemComponent
      },
      {
        path: environment.currentDeal.url,
        component: OutletItemComponent
      },
    ],
  },
  {
    path: 'guest',
    component: BuyerHomeComponent,
    children: [
      {
        path: 'just_sold',
        component: NotificationItemsComponent
      },
      {
        path: 'live_offers',
        loadComponent: () => import('./buyers-offers/buyers-offers.component').then(c => c.BuyersOffersComponent)
      },
      {
        path: 'live_offers/:liveOfferId',
        loadComponent: () => import('./buyers-offers/buyers-offers.component').then(c => c.BuyersOffersComponent)
      },
      {
        path: 'price-drop',
        component: ItemsListComponent
      },
      {
        path: 'just_launched',
        component: ItemsListComponent
      },
      {
        path: environment.currentDeal.url,
        component: ItemsListComponent
      },
      {
        path: 'home',
        component: LandingPageComponent
      },
      {
        path: 'past_purchases',
        component: PastPurchaseItemsComponent
      },
      {
        path: 'items/:code',
        component: ItemsListComponent
      },
      {
        path: 'user_filters/:id',
        component: UserFiltersComponent
      },
      {
        path: '**',
        component: ItemsListComponent
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyerRoutingModule { }
