<div *ngIf="topService.loading" class="loader"></div>
<div *ngIf="selectedOrder.docNumber" class="full-order-global">
  <div class="top">
    <div class="header" [class.admin-header]="isSuper">
      <!--change 'true' -> 'isAdmin'-->
      <div class="header-left">
        <span class="back" routerLink="/user/orders"></span>
        Order #{{selectedOrder.docNumber}}
      </div>
      <ul class="header-info">
        <li>
          <div class="header-info__label">Order Status</div>
          <div class="header-info__value __status">
            {{ordersService.statusColorMap[selectedOrder.progressStatus].description}}
          </div>
        </li>
        <!-- <li>
          <div class="header-info__label">Authorization</div>
          <div class="header-info__value">{{selectedOrder?.payment?.authorization}}</div>
        </li> -->
        <!-- <li class="_admin-show">
          <div class="header-info__label">Charge Reference</div>
          <div class="header-info__value" *ngIf="selectedOrder?.payment?.status === 'Applied'">
            {{selectedOrder?.payment?.charge}}</div>
        </li> -->
        <li class="_admin-show">
          <div class="header-info__label">Order Date</div>
          <div class="header-info__value">{{selectedOrder?.orderDate | date:"shortDate"}}</div>
        </li>
        <li class="_admin-show" *ngIf="selectedOrder?.payment?.id">
          <div class="header-info__label">Order Date Charged</div>
          <div class="header-info__value" *ngIf="selectedOrder?.payment?.status === 'Applied'">
            {{selectedOrder?.payment?.orderDate | date:"shortDate"}}</div>
        </li>
        <li class="_admin-show __admin-amount">
          <div class="header-info__label">
            <span *ngIf="selectedOrder?.payment?.status === 'Applied'">Charge </span>
            <span *ngIf="selectedOrder?.payment?.status === 'Authorize'">Authorize </span>
            Amount
          </div>
          <div class="header-info__value">{{selectedOrder?.payment?.amount | CustomCurrency}}</div>
        </li>


        <li class="_admin-hide" *ngIf="selectedOrder?.payment?.id">
          <div class="header-info__label">Date</div>
          <div class="header-info__value">
            {{selectedOrder?.payment?.orderDate | date:"shortDate"}}
          </div>
        </li>
        <li class="_admin-hide">
          <div class="header-info__label">
            <span *ngIf="selectedOrder?.payment?.status === 'Applied'">Charge </span>
            <span *ngIf="selectedOrder?.payment?.status === 'Authorize'">Authorize </span>
            Amount
          </div>
          <div class="header-info__value">{{selectedOrder?.payment?.amount | CustomCurrency}}</div>
        </li>


        <li *ngIf="selectedOrder?.payment?.id" class="__nickname">
          <div class="header-info__label">Card Nickname</div>
          <div class="header-info__value">{{selectedOrder?.payment?.cardName}}</div>
        </li>
      </ul>
      <ul class="header-controls">
        <li>
          <a (click)="!topService.loading?gotoShopping():''" class="btn continue-shop">Continue Shopping</a>
        </li>
        <li class="pdf-btn"
          *ngIf="isSuper && selectedOrder?.payment?.status === 'Authorize' && !selectedOrder.beingUpdated"
          (click)="showChargeModal()">
          <a class="charge-btn">
            <img src="assets/images/icons/profile/credit-card.svg" height="24" width="24" />
            <span>Charge</span>
          </a>
        </li>
        <li class="pdf-btn">
          <a (click)="!topService.loading?openPdf($event,selectedOrder.docEntry):''" class="download-btn">
            <img src="assets/images/icons/header/price-list-white.svg" height="24" width="24" />
            <span>Download</span>
          </a>
        </li>
        <li *ngIf="canEditOrder() && !selectedOrder.beingUpdated" class="pdf-btn">
          <a (click)="updateOrderConfirmDialog($event, selectedOrder)" class="edit-btn">
            <img src="assets/images/edit-order-white.svg" height="24" width="24" />
            <span>Edit Order</span>
          </a>
        </li>
        <li class="pdf-btn" *ngIf="canSplitOrCancelOrder() && !selectedOrder.beingUpdated"
          (click)="splitOrder(selectedOrder)">
          <a class="split-btn">
            <img src="assets/images/icons/rebranding/split-white.svg" height="24" width="24" />
          </a>
        </li>
        <li *ngIf="canSplitOrCancelOrder() && !selectedOrder.beingUpdated" class="pdf-btn">
          <a (click)="cancelConfirm(selectedOrder)" class="cancel-btn">
            <img src="assets/images/close-white.svg" height="24" width="24" />
            <span>Cancel Order</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-lg-12 invoice">

    <div
      style="width:960px;height:1250px;min-width:960px;min-height:1250px;margin:20px auto 0 auto;">

      <div style="clear:both;"></div>

      <div style="padding:20px">
        <div style="float: left;">

          <div style="margin-top:40px;">
            <img src="assets/images/logo-black.svg" height="46" width="169" />
          </div>
          <ul style="margin:34px 0 0 0;padding:0;list-style: none; color: #000000;">
            <li style="font-size: 14px;line-height:21px;">Order Status</li>
            <li style="font-size: 26px;line-height:32px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.7px;text-transform: uppercase;">
              {{ordersService.statusColorMap[selectedOrder.progressStatus].description}}
            </li>
          </ul>
        </div>

        <div style="padding-top:40px;">
          <div style="float:right; width: 306px;">

            <div style="float:left;padding-right:15px;">
              <ul style="padding:0;margin:0;list-style:none;text-align:left;">
                <li style="font-size: 26px;line-height:32px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.7px;">
                  Order #
                </li>
                <li style="font-size: 14px;line-height:21px;">
                  Date:
                </li>
                <li style="font-size: 14px;line-height:21px;">
                  Due Date:
                </li>
                <li style="font-size: 14px;line-height:21px;">
                  Customer No.:
                </li>
                <li style="font-size: 14px;line-height:21px;">
                  Customer Ref. No.:
                </li>
              </ul>
            </div>

            <div style="float:right;padding-left:15px;border-left-width:2px;">
              <ul style="padding:0;margin:0;list-style:none;text-align:right;">
                <li style="font-size: 26px;line-height:32px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.7px;">{{selectedOrder.docNumber}} </li>
                <li style="font-size: 14px;line-height:21px;">{{selectedOrder.orderDate | date:'shortDate'}}</li>
                <li style="font-size: 14px;line-height:21px;">{{selectedOrder.dueDate ? (selectedOrder.dueDate | date:'shortDate') : ' — '}}</li>
                <li style="font-size: 14px;line-height:21px;">{{selectedOrder.cardCode}}</li>
                <li *ngIf="selectedOrder.payment && selectedOrder.payment.id" style="font-size: 14px;line-height:21px;">{{ordersService.getExternalId(selectedOrder)}}</li>
                <li *ngIf="!(selectedOrder.payment && selectedOrder.payment.id)" style="font-size: 14px;line-height:21px;">—</li>
              </ul>
            </div>


          </div>
        </div>

        <div style="clear:both;"></div>

        <div style="padding-top:28px;">
          <table border="0" style="width:100%;">
              <tr>
                <td>
                  <table border="0" style="width:100%;">
                    <tr>
                      <td style="width: 16.66%; text-align: left;font-size: 14px;line-height:21px;" [style.fontFamily]="selectedOrder.progressStatus === SalesOrderStatus.PendingPayment ? 'HelveticaNeueCyr-Bold' : 'HelveticaNeueCyr-Roman1'">Pending Payment</td>
                      <td style="width: 16.66%; text-align: center;font-size: 14px;line-height:21px;" [style.fontFamily]="selectedOrder.progressStatus === SalesOrderStatus.PaymentApproved ? 'HelveticaNeueCyr-Bold' : 'HelveticaNeueCyr-Roman1'" >Payment Approved</td>
                      <td style="width: 16.66%; text-align: center;font-size: 14px;line-height:21px;" [style.fontFamily]="selectedOrder.progressStatus === SalesOrderStatus.ProcessingTransit ? 'HelveticaNeueCyr-Bold' : 'HelveticaNeueCyr-Roman1'" >Processing Transit</td>
                      <td style="width: 16.66%; text-align: center;font-size: 14px;line-height:21px;" [style.fontFamily]="selectedOrder.progressStatus === SalesOrderStatus.Processing ? 'HelveticaNeueCyr-Bold' : 'HelveticaNeueCyr-Roman1'" >Processing</td>
                      <td style="width: 16.66%; text-align: center;font-size: 14px;line-height:21px;" [style.fontFamily]="selectedOrder.progressStatus === SalesOrderStatus.Ready ? 'HelveticaNeueCyr-Bold' : 'HelveticaNeueCyr-Roman1'" >Ready</td>
                      <td style="width: 16.66%; text-align: right;font-size: 14px;line-height:21px;" [style.fontFamily]="selectedOrder.progressStatus === SalesOrderStatus.Shipped ? 'HelveticaNeueCyr-Bold' : 'HelveticaNeueCyr-Roman1'" >Shipped</td>
                    </tr>
                    <tr>
                      <td colspan="6" style="width: 100%;padding-top: 11px;">
                        <div  style="width: 100%;border-bottom: 1.75px dotted #000000;">
                            <div class="solid-line" [style.width]="getStatusProgressWidth()"  style="border-bottom: 5px solid #000000; float: left;margin-top: -1px;"></div>
                            <div class="dot" style="width: 10px;height: 10px; border-radius: 10px; background-color: #000000; float:left;margin-top: -4px; margin-left: -1px;"></div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
          </table>
        </div>

        <div style="margin-top:28px; width: 100%;">
          <div style="float:left;min-width:25%;width:25%;">
            <ul style="padding:0;margin:0;list-style:none;">
              <li style="color: #000000;margin-bottom: 8px;font-size: 26px;line-height:31px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.7px;">BILL TO
              </li>
              <li style="font-size: 14px;line-height:21px;">{{selectedOrder.billingAddressCode}}</li>
              <li *ngFor="let shipping of shipping_address" style="font-size: 14px;line-height:21px;">{{shipping}}</li>
              <li></li>
            </ul>
          </div>

          <div style="float:left;min-width:25%;width:25%;">
            <ul style="padding:0;margin:0;list-style:none;float: right;">
              <li style="color: #000000;margin-bottom: 8px;font-size: 26px;line-height:31px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.7px;">SHIP TO
              </li>
              <li style="font-size: 14px;line-height:21px;">{{selectedOrder.shippingAddressCode}}</li>
              <li *ngFor="let billing of billing_address" style="font-size: 14px;line-height:21px;">{{billing}}</li>
              <li></li>
            </ul>
          </div>
          <div style="float: left; min-width:50%; width: 50%;">
            <ul style="padding:0 0 0 155px;margin:0;list-style: none;">
              <li style="font-size: 14px;line-height:21px;">
                Sales Employee: {{selectedOrder.salesEmployee}}</li>
              <li style="font-size: 14px;line-height:21px;">
                Contact Name: {{selectedOrder.contactName}}</li>
              <li style="font-size: 14px;line-height:21px;">
                Contact Email: {{selectedOrder.contactEmail}}</li>
              <li style="font-size: 14px;line-height:21px;">
                Canceled: {{selectedOrder.isCancelled ? 'Yes' : 'No'}}</li>
            </ul>
          </div>
        </div>


        <div style="clear:both;"></div>

        <div style="margin-top:28px;">
          <table style="width:100%;border-spacing:0;">
            <thead>
              <tr>
                <th
                  style="border-top:1px solid #1A1A1A;text-align:left;font-size: 14px;line-height:18px;padding:7px 0px 10px 0px;">
                  Item No.</th>
                <th
                  style="border-top:1px solid #1A1A1A;text-align:left;font-size: 14px;line-height:18px;padding:7px 0px 10px 0px;">
                  Description</th>
                <th
                  style="border-top:1px solid #1A1A1A;text-align:left;font-size: 14px;line-height:18px;padding:7px 0px 10px 33px;">
                  EXW</th>
                <th
                  style="border-top:1px solid #1A1A1A;text-align:right;font-size: 14px;line-height:18px;padding:7px 0px 10px 0px;">
                  Unit Price</th>
                <th
                  style="border-top:1px solid #1A1A1A;text-align:right;font-size: 14px;line-height:18px;padding:7px 0px 10px 0px;">
                  Qty</th>
                <th style="border-top:1px solid #1A1A1A;text-align:right;font-size: 14px;line-height:18px;padding:7px 0px 10px 0px;">Total
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let lines of selectedOrder.orderDetails">
                <td style="border-top:1px solid #e5e5e5;width:134px;padding: 9px 0;font-size: 14px;line-height:21px;">{{lines.itemCode}}</td>
                <td style="border-top:1px solid #e5e5e5;width:448px;padding: 9px 0;font-size: 14px;line-height:21px;">{{ lines.description }}
                </td>
                <td style="border-top:1px solid #e5e5e5;padding: 9px 0 9px 33px;text-align:left;font-size: 14px;line-height:21px;">{{lines.exwPoint}}</td>
                <td style="border-top:1px solid #e5e5e5;padding: 9px 0;text-align:right;font-size: 14px;line-height:21px;">{{lines.unitPrice | currency:'USD':'symbol-narrow'}}</td>
                <td style="border-top:1px solid #e5e5e5;padding: 9px 0;text-align:right;font-size: 14px;line-height:21px;">{{lines.quantity}}</td>
                <td style="border-top:1px solid #e5e5e5;width:110px;padding: 9px 0;text-align:right;font-size: 14px;line-height:21px;">
                  {{ (lines.unitPrice * lines.quantity) | currency:'USD':'symbol-narrow'}}</td>
              </tr>

            </tbody>
          </table>
        </div>

        <div style="clear:both;"></div>

        <div>
          <table  cellspacing="0"
                  cellpadding="0"
                  border="0"
                  width="100%">
            <tbody>
              <tr>
                <td width="274px" valign="top" style="padding: 0 0 0 0;">
                  <div style="width: 100%;border-top: 1px solid #1A1A1A;">
                    <div style="padding-top:11px;">
                      <ul style="padding:0;margin:0;list-style: none;font-size: 14px;line-height:21px;">
                        <li style="padding-bottom: 7px;font-size: 15px;line-height:21px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.15px;text-transform: uppercase;">
                          Beneficiary Bank
                        </li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">Bank Name:</span><br/>{{userService.orderBankInfo?.bankName || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">Bank Address:</span><br/>{{userService.orderBankInfo?.address || '' }}
                        <br/>{{userService.orderBankInfo?.city || '' }}, {{userService.orderBankInfo?.stateProvince || '' }}, {{userService.orderBankInfo?.country || '' }} {{userService.orderBankInfo?.zipCode || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">Account Routing (ABA):</span><br>{{userService.orderBankInfo?.routingNumber || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">ACH:</span><br>{{userService.orderBankInfo?.ach || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">SWIFT/BIC CODE:</span><br>{{userService.orderBankInfo?.swiftBicCode || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">ACCOUNT Number:</span><br>{{userService.orderBankInfo?.accountNumber || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">Intermediary BIC:</span><br>{{userService.orderBankInfo?.intermediaryBIC || '' }}</li>
                        <li style="padding-bottom: 7px;padding-top: 16px;font-size: 15px;line-height:21px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.15px;text-transform: uppercase;">
                          Beneficiary Company
                        </li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">Beneficiary Name:</span><br>{{userService.orderBankInfo?.beneficiaryName || '' }}</li>
                        <li style="padding-bottom: 7px;"><span style="font-family: HelveticaNeueCyr-Bold;">Beneficiary Address:</span><br>{{userService.orderBankInfo?.beneficiaryAddress || '' }}
                          <br/>{{userService.orderBankInfo?.beneficiaryCity || '' }}, {{userService.orderBankInfo?.beneficiaryStateProvince || '' }}, {{userService.orderBankInfo?.beneficiaryCountry || '' }} {{userService.orderBankInfo?.beneficiaryZipCode || '' }}</li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td valign="top" style="padding: 0 0 0 32px;">
                    <div style="width: 614px;border-top: 1px solid #1A1A1A;">
                      <div style="display:inline-block;padding: 9px 0 10px;">
                        <ul style="padding:0;margin:0;list-style:none;text-align:left;font-size: 14px;line-height:21px;">
                          <li>Subtotal</li>
                          <li>Convenience Fee</li>
                          <li>Discount</li>
                          <li>Freight</li>
                          <li>Tax</li>
                        </ul>
                      </div>

                      <div style="float:right; padding: 9px 0 10px;">
                        <ul style="padding:0;margin:0;list-style:none;text-align:right;font-size: 14px;line-height:21px;">
                          <li>{{ totalAmount() | currency:'USD':'symbol-narrow'}} </li>
                          <li>{{ this.selectedOrder?.payment?.fee | currency:'USD':'symbol-narrow'}}</li>
                          <li *ngIf="userDiscount>0">-{{ userDiscount | currency:'USD':'symbol-narrow' }}</li>
                          <li *ngIf="userDiscount===0"> {{ 0 | currency:'USD':'symbol-narrow' }}</li>
                          <li>{{selectedOrder.shippingCost | currency:'USD':'symbol-narrow'}}</li>
                          <li>{{ tax | currency:'USD':'symbol-narrow' }}</li>
                        </ul>
                      </div>
                    </div>
                    <div _ngcontent-ng-c2342997424="" style="clear: both;"></div>
                    <div style="width: 614px;border-top: 1px solid #1A1A1A;">
                      <div style="display:inline-block;padding-top:9px;">
                        <ul style="padding:0;margin:0;list-style:none;text-align:left;font-size: 26px;line-height:31px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.7px;text-transform: uppercase;">
                          <li>Total Order Value</li>
                        </ul>
                      </div>
                      <div style="float:right; padding-top: 9px;">
                        <ul style="padding:0;margin:0;list-style:none;text-align:right;font-size: 26px;line-height:31px;font-family: HelveticaNeueCyr-Bold;">
                          <li>{{ selectedOrder.orderTotal | currency }}</li>
                        </ul>
                      </div>
                    </div>

                    <div style="width: 614px;margin-top:15px;">
                      <ul
                        style="padding:0;margin:0;list-style:none;">
                        <li style="font-size:14px; line-height: 18px;word-wrap: break-word;">
                          <span style="font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.15px; text-transform: uppercase;">Remarks:</span>&nbsp;{{selectedOrder.comments}}</li>
                        <div style="clear:both;"></div>
                      </ul>
                    </div>

                    <div style="width: 614px;margin-top:8px;text-align:justify;font-size:14px; line-height: 18px;letter-spacing: -0.3px;">
                      <span style="padding:0px 0px 4px 0px;width:100%;font-family: HelveticaNeueCyr-Bold;text-align: left;letter-spacing: 0.15px;">
                        TERMS &amp; CONDITIONS
                      </span>
                      <br/>
                      <i style="padding:0px 0px 4px 0px;width:100%;text-align: left;font-family: HelveticaNeue-Italic;">Failure to comply with the following terms and conditions will result in the termination of your
                        account.</i>
                      <br> It is our policy to comply with all US federal Laws and regulations, including the US Money
                      Laundering Control Act, the Foreign Corrupt Practices Act, and all currency reporting
                      requirements. HUBX will not accept third-party payments on behalf of any customer.  All your
                      payments must be made in US Dollars, and you the customer represents and warrants that such
                      dollar payments are derived from legitimate sources. We, also, reserve the right to terminate
                      any account for any reason whatsoever.
                    </div>

                    <div style="max-width:614px;margin-top:4px;text-align:justify;font-size:14px;line-height: 18px;">
                      <ul style="padding-left: 15px;margin: 0;list-style-type: '** ';">
                        <li>No products sold by hubx.com are manufactured by HUBX, however, certain products may
                          be covered by a manufacturer's limited warranty.  HUBX assigns to the customer any warranty
                          of the manufacturer, and customer acknowledges that it shall have recourse only under such
                          warranties and only against the manufacturer. HUBX makes no representation or express
                          warranty with respect to the product, except those stated herein.</li>
                        <li>We guarantee to ship the part numbers you order. It is your responsibility to verify the
                          specifications with the manufacturer. </li>
                        <li>No cancellation of orders or product returns are permitted. All Sales are final.  If HUBX, in its
                          sole discretion, permits the cancellation of an order, such order shall be subject to a 15%
                          restocking fee. </li>
                        <li>A finance charge of 1.5% per month will be imposed on past due amounts. Unless prohibited
                          by applicable law, HUBX reserves the right to offset any credit amounts held in a customer’s
                          account against any amounts due to HUBX.</li>
                      </ul>
                    </div>
                </td>
              </tr>
              <tr>
                <td width="274px" valign="bottom" style="padding: 0 0 0 0;">
                  <div *ngIf="userService.orderBankInfo" style="width: 100%;">
                    <div style="padding-top:16px;">
                      <ul style="padding:0;margin:0;list-style: none;font-size: 14px;line-height:21px;">
                        <li style="font-size: 15px;line-height:21px;font-family: HelveticaNeueCyr-Bold;letter-spacing: 0.15px;">
                          SALES ORDER # MUST BE<br> INCLUDED IN WIRE REFERENCE
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
                <td valign="bottom" style="padding: 0 0 0 32px;">
                  <div style="max-width:614px;margin-top:4px;text-align:justify;font-size:14px;line-height: 18px;letter-spacing: -0.3px;">
                      <ul style="padding:0;margin:0;list-style: none;">
                        <li><span style="font-family: HelveticaNeueCyr-Bold;">PLEASE NOTE:</span> All Dell part numbers ending in PC, SA, or SD can only be resold to the following countries: USA, CANADA, ISRAEL, THE NETHERLANDS, SAUDI ARABIA, UNITED ARAB EMIRATES </li>
                      </ul>
                    </div>
                </td>

            </tbody>
          </table>
        </div>

        <div style="clear:both;"></div>

        <div *ngIf="selectedOrder.payment?.id" style="margin-top:35px;">
          <div style="padding: 30px 28px 34px;width:100%;background:rgba(26, 26, 26, 1); border-radius: 12px;">
            <div style="width: 64px;float: left;margin-right: 28px;margin-top: 6px;">
              <img src="assets/images/order/64-terms.svg" height="64" width="64" />
            </div>
            <ul style="padding:0;margin:0;list-style:none;max-width: 598px;">
              <li style="font-size:28px;line-height:36px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">All Purchases are Subject to the </li>
              <li style="font-size:28px;line-height:36px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">following Terms and Conditions</li>
            </ul>
          </div>


          <div style="float:left;width: 50%;padding-right: 12px;">
            <div style="margin-top:64px;">
              <ul style="padding:0;margin:0;list-style: none;font-size:14px;line-height: 20px;text-align:justify;">
                <li>By placing a check mark in the adjacent box, you are indicating your </li>
                <li>acceptance and agreement with the foregoing terms and conditions, </li>
                <li>including a 3% Convenience Fee for all online purchases by card credit</li>
                <li>(incurred as a result of the ease and convenience associated with</li>
                <li>conducting this transaction online), and acknowledging that you are a</li>
                <li>legally authorized user of this credit card.</li>
                <li style="height: 20px;width: 100%;"></li>
                <li>The prices and available quantities of certain Products are subject to</li>
                <li>fluctuate due to market conditions beyond our control. We do not provide</li>
                <li>warranties for Products, other than limited warranties for certain select</li>
                <li>Products clearly identified on such Product’s listing ID. However, we do</li>
                <li>not provide or administer any warranty service. To the extent your Product</li>
                <li>is covered by any warranty, we would refer you to the warranty information</li>
                <li>contained in your Product’s original packaging. It is your sole</li>
                <li>responsibility to verify the accuracy of your shipping information, part</li>
                <li>numbers, and product specifications.</li>
              </ul>
            </div>
          </div>

          <div style="float: right;width: 50%;margin-top:64px;text-align:justify;font-size:14px;line-height: 20px;padding-left: 12px;">
            <div style="padding:5px 0px 5px 0px;width:100%;border: 1px solid rgba(26, 26, 26, 1); border-radius: 8px;">
              <ul style="padding:0;margin:0;list-style:none;padding-left: 14px;font-family: 'HelveticaNeueCyr-Bold';">
                <li>NO RETURNS OR REFUNDS. ALL SALES ORDERS ARE FINAL</li>
                <li>– A 15% RESTOCKING FEE APPLIES ALL CANCELLATIONS.</li>
              </ul>
            </div>
            <ul style="padding:0;margin:0;list-style:none; margin-top: 18px;padding-left: 14px;">
              <li>In an effort to provide you the ease and convenience of making online</li>
              <li>purchases of our Products, we allow our customers to make purchases by</li>
              <li>credit card, subject to a 3% Convenience Fee. HUBX reserves the right to</li>
              <li>cancel any Sales Order if confirmation of payment is not received within</li>
              <li>72 business hours. At the time of the receipt of your shipment, any</li>
              <li>damage to exterior packaging must be noted prior to signing for</li>
              <li>merchandise. If the carrier does not have such notes an insurance claim</li>
              <li>will not be filed, and you will be deemed to have inspected and accepted</li>
              <li>the Products. Orders may take up to 2 business days, after credit release,</li>
              <li>to pick and pack.</li>
            </ul>
          </div>
        </div>
        <div style="clear:both;"></div>

        <div style="margin-top:72px;box-shadow: 0 6px 20px 0 rgba(0,0,0,0.19); border-radius: 12px; overflow: hidden; padding-bottom: 56px;"
        *ngIf="selectedOrder.payment?.id">
          <div style="padding: 28px 28px 29px;width:100%;background:rgba(26, 26, 26, 1); border-radius: 12px;">
            <div style="width: 64px;float: left;margin-right: 28px;">
              <img src="assets/images/order/64-check.svg" height="64" width="64" />
            </div>
            <ul style="padding:0;margin:0;list-style:none;max-width: 598px;margin-top: 3px;">
              <li style="font-size: 21.6px;line-height: 28.8px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">I agree to terms and conditions of placing order</li>
              <li style="font-size: 21.6px;line-height: 28.8px;color:#FFFFFF;font-family: 'HelveticaNeueCyr-Bold';">and I am an authorized agent to place order.</li>
            </ul>
          </div>


          <div style="float:left;width: 390px;padding-right: 12px;margin-left: 120px;">
            <div style="margin-top:50px;">
              <ul style="padding:0;margin:0;list-style: none;text-align:left;">
                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;">Company Name</li>
                <li style="font-size: 18px; color: #1A1A1A; line-height: 22px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 4px;">
                  {{ selectedOrder.cardName }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px; margin-top: 31px;">Electronic Authorized Agent Signature</li>
                <li style="font-size: 18px; color: #1A1A1A; line-height: 22px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 4px;">
                  {{ selectedOrder.payment.firstName }} {{ selectedOrder.payment.lastName }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px; margin-top: 30px;">IP Address</li>
                <li style="font-size: 18px; color: #1A1A1A; line-height: 22px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 4px;">
                  {{ selectedOrder.payment.ipAddress }}
                </li>
              </ul>
            </div>
          </div>
          <div style="float:left;width: 220px;padding-right: 12px;padding-left: 14px;">
            <div style="margin-top:50px;">
              <ul style="padding:0;margin:0;list-style: none;text-align:left;">
               <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;">HUBX Business Partner</li>
               <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                #{{  selectedOrder.cardCode }}
              </li>

               <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 32px;">HUBX Order</li>
               <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                #{{ selectedOrder.docNumber }}
              </li>

               <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 31px;">Credit Card</li>
               <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 6px;">
                <span style="font-size: 14px; color: #1A1A1A; line-height: 20px;vertical-align: middle;font-family: 'HelveticaNeueCyr-Bold';padding-right:9px;">
                  **** {{ selectedOrder.payment.cardLast4Digits }} </span><img [src]="'assets/images/order/' + selectedOrder.payment.cardType + '.svg'" height="20" width="62" />
              </li>
              </ul>
            </div>
          </div>
          <div style="float:left;width: 150px;padding-right: 12px;">
            <div style="margin-top:50px;">
              <ul style="padding:0;margin:0;list-style: none;text-align:left;">
                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;">Date</li>
                <li style="margin-top: 7px;font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; ">
                  {{ selectedOrder.payment.orderDate | date:'mediumDate' }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 31px;">Time</li>
                <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                  {{ selectedOrder.payment.orderDate | date:'H:mm' }}
                </li>

                <li style="font-size: 13px; color: #1A1A1A; line-height: 18px;margin-top: 33px;">Authorized Amount</li>
                <li style="font-size: 14px; color: #1A1A1A; line-height: 17px; font-family: 'HelveticaNeueCyr-Bold'; margin-top: 8px;">
                  ${{ selectedOrder.payment.amount }}
                </li>
              </ul>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- EDIT ORDER CONFIRM POPUP -->
  <div class="edit-confirm" *ngIf="isShowModalOrShowCancelModal()">
    <div class="add-to-wrap animated" [class.zoomIn]="animateIn" [class.zoomOut]="animateOut">
      <!-- <div (click)="showModal = !showModal" class="close"></div> -->
      <div class="message">
        <div *ngIf="!this.cancelError">
          {{ popUpTextMessages() }}
        </div>
        <div *ngIf="this.cancelError" class="cancel-error">
          {{ popUpTextMessages() }}
        </div>
      </div>

      <div class="bottom-controls">
        <div>
          <div class="container">
            <div class="row">
              <div class="btn btn-cancel col-xs-6 col-md-6">
                <button *ngIf="showModal" (click)="closeModal()" class="cancel"
                  [disabled]="topService.loading">Cancel</button>
                <button *ngIf="showCancelModal" [ngClass]="{'hide': cancelError}" (click)="closeCancelConfirm()"
                  class="cancel" [disabled]="topService.loading">No</button>
              </div>
              <div class="btn btn-continue col-xs-6 col-md-6">
                <button *ngIf="showModal" class="continue" [disabled]="topService.loading"
                  (click)="editOrder(selectedOrder.docEntry);">Continue</button>
                <button *ngIf="showCancelModal" [ngClass]="{'hide': cancelError}" (click)="cancelOrderYes()"
                  class="continue" [disabled]="topService.loading">Yes</button>
              </div>
              <div class="btn btn-error  col-xs-12 col-md-12">
                <button *ngIf="cancelError" (click)="closeCancelConfirm()" [disabled]="topService.loading" class="cancel">Got it</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="charge-modal" *ngIf="showChargeOrder">
    <div class="charge-modal-dialog">
      <div class="charge-modal-content">
        <div class="charge-modal-body">
          Charge Order # {{ selectedOrder.docNumber }}<br />{{ selectedOrder.orderTotal | CustomCurrency }}
        </div>
        <div class="charge-modal-footer">
          <button type="button" class="button-secondary" (click)="showChargeOrder = false;">Cancel</button>
          <button type="button" class="button-primary" (click)="chargeOrder()"
            [disabled]="topService.loading">Charge</button>
        </div>
      </div>
    </div>
  </div>
  <div class="charge-modal" *ngIf="showChargedOrder">
    <div class="charge-modal-dialog">
      <div class="charge-modal-content">
        <div class="charge-modal-body">
          Order # {{ selectedOrder.docNumber }} has been <br />successfully charged
          {{ selectedOrder.orderTotal | CustomCurrency }}
        </div>
        <div class="charge-modal-footer">
          <button type="button" class="button-primary" (click)="closeChargedModal()">Got it</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-error-modal [bodyText]="errorModal.bodyText" [title]="errorModal.title" [isError]="errorModal.isShown"
  (onConfirmError)="hideError()"></app-error-modal>
