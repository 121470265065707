import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { OrdersService } from './orders.service';

import * as models from '../../interfaces/model';
import { IOrder } from '../../interfaces/model';
import { Observable, of } from 'rxjs';

@Injectable()
export class CompactOrderResolve implements Resolve<models.Order> {
  constructor(private ordersService: OrdersService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<models.Order> {

    return this.ordersService.selectedOrder?.docNumber ?
      of(this.ordersService.selectedOrder) : this.ordersService.getOrder(route.paramMap.get('docEntry'));
  }
}