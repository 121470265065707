import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './user.service';
import { TopService } from '../shared/services/top.service';
import { SharedService } from '../shared/shared.service';
import { SessionService } from '../services/session.service';
import { SplitOrderComponent } from '../buyer/order-history/split-order/split-order.component';
import { OffersComponent } from './offers/offers.component';
import { OrdersComponent } from '../buyer/order-history/orders/orders.component';
import { OfferChatMessageComponent } from './offers/offer-chat-message/offer-chat-message.component';
import { BottomNavMobileComponent } from '../shared/bottom-nav-mobile/bottom-nav-mobile.component';
import { RouterOutlet } from '@angular/router';
import { UserNavigationComponent } from './user-navigation/user-navigation.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    standalone: true,
    imports: [NgIf, UserNavigationComponent, RouterOutlet, BottomNavMobileComponent]
})
export class UserComponent implements OnInit, OnDestroy {
  showNavigation = true;
  role: string;
  isSplitOrderPadge = false;
  isOfferPage = false;
  isOfferChat = false;
  isOrdersList = false;

  constructor(
    public topService: TopService,
    public userService: UserService,
    private sessionService: SessionService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.topService.trendingNavVisible = true;
    this.sharedService.isCorpSite = false;
    this.role = this.sessionService.userRole;

    if (this.role === 'ADMIN' || this.role === 'SELLER' || this.role === 'VENDORMGR') {
      this.userService.isVendor = true;
    } else {
      this.userService.isVendor = false;
    }

    if (this.userService.isVendor || this.isSplitOrderPadge) {
      this.showNavigation = false;
    } else {
      this.showNavigation = true;
    }

  }

  ngOnDestroy() {
    this.topService.trendingNavVisible = false;
  }

  onActivate(event: Event): void {
    console.log(event);
    if (event instanceof SplitOrderComponent) {
      this.showNavigation = false;
      this.isSplitOrderPadge = true;
    } else {
      this.showNavigation = true;
      this.isSplitOrderPadge = false;
    }

    if (event instanceof OffersComponent) {
      this.isOfferPage = true;
    } else {
      this.isOfferPage = false;
    }

    if (event instanceof OfferChatMessageComponent) {

      this.isOfferChat = true;
    } else {
      this.isOfferChat = false;
    }

    if (event instanceof OrdersComponent) {
      this.isOrdersList = true;
    } else {
      this.isOrdersList = false;
    }


  }

}

