<div class="user-navigation" [class.offers-page]="isOfferPage" [class.orders-list]="isOrdersList">
  <div class="user-navigation__header">
    <h3>My Account</h3>
  </div>
  <div class="user-navigation__content">
    <ul class="user-navigation-list">
      <li *ngIf="isBuyerPortal()">
        <a class="user-navigation-list-link" [routerLink]="['/user/orders']" routerLinkActive="_active">
          <img src="assets/images/icons/profile/order-history.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">Order History</span>
        </a>
      </li>
      <li *ngIf="isBuyerPortal()">
        <a class="user-navigation-list-link" [routerLink]="['/user/mobile_notifications']" routerLinkActive="_active">
          <img src="assets/images/icons/profile/mobile-sms.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">Mobile | SMS</span>
        </a>
      </li>
      <li *ngIf="isBuyerPortal() && userService.isCreditCardAllowed">
        <a class="user-navigation-list-link" [routerLink]="['/user/credit_cards']" routerLinkActive="_active">
          <img src="assets/images/icons/profile/24-credit-card-white.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">Credit Cards</span>
        </a>
      </li>
      <li *ngIf="isBuyerPortal()">
        <a class="user-navigation-list-link" [routerLink]="['/user/filters']" routerLinkActive="_active">
          <img src="assets/images/icons/profile/24-saved-searches.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">Saved Searches</span>
        </a>
      </li>
      <li>
        <a class="user-navigation-list-link" [routerLink]="['/user/support']" routerLinkActive="_active">
          <img src="assets/images/icons/profile/support.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">Support Team</span>
        </a>
      </li>
      <li *ngIf="isBuyerPortal()">
        <a class="user-navigation-list-link __auctions" [routerLink]="['/auctions/my_bids']" routerLinkActive="_active">
          <i><img src="assets/images/icons/rebranding/24-auctions.svg" width="24" height="24" /></i>
          <img class="dots" src="assets/images/icons/header/green-dot.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">Live Auctions | My Bids</span>
        </a>
      </li>
      <li>
        <a class="user-navigation-list-link" [routerLink]="['/user/offers']" routerLinkActive="_active">
          <img src="assets/images/icons/rebranding/offers-header.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">My Offers</span>
          <span *ngIf="userRole == 'BUYER' && offerService.offersSummary?.unseenEventCount > 0"
            class="_badge-counter">
            {{offerService.offersSummary?.unseenEventCount}}
          </span>
          <span *ngIf="isSuperOrSaleImpersonating && offerService.bpUnseenEventCount > 0"
            class="_badge-counter">
            {{ offerService.bpUnseenEventCount}}
          </span>
          <span *ngIf="(userRole == 'BUYER' && offerService.offersSummary?.unseenEventCount > 0) || (isSuperOrSaleImpersonating && offerService.bpUnseenEventCount > 0)"
            class="_badge-counter__dot">
          </span>
        </a>
      </li>
      <li>
        <a class="user-navigation-list-link" [routerLink]="['/user/profile']" routerLinkActive="_active">
          <img src="assets/images/icons/profile/profile.svg" width="24" height="24" />
          <span class="user-navigation-list-link__text">User Profile</span>
        </a>
      </li>
    </ul>
  </div>
</div>