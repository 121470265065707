<div class="user-page-wrapper" [class.mobile]="topService.isMobile" [class.offer-page]="isOfferPage" [class.orders-list]="isOrdersList">
  <div class="user-navigation-wrapper" *ngIf="showNavigation && !topService.isMobile">
    <app-user-navigation [isOfferPage]="isOfferPage" [isOrdersList]="isOrdersList"></app-user-navigation>
  </div>
  <div class="user-content-wrapper">
    <router-outlet (activate)='onActivate($event)'></router-outlet>
  </div>
</div>

<app-bottom-nav-mobile [showBackBtn]="true" *ngIf="topService.isMobile"></app-bottom-nav-mobile>


