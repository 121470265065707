import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { OrdersService } from '../orders/orders.service';
import { UserService } from '../../../user/user.service';
import { TopService } from '../../../shared/services/top.service';
import { PubSubService } from '../../../core/pubsub.service';
import { SharedSource } from '../../../core/shared-source';
import { SessionService } from '../../../services/session.service';

import * as models from '../../interfaces/model';
import { SharedService } from '../../../shared/shared.service';
import { OrderStatusEnum } from '../../enums/orderStatusEnum';
import { CustomCurrencyPipe } from '../../../shared/pipes/currency.pipe';
import { ErrorModalComponent } from '../../../shared/ui-components/error-modal/error-modal.component';
import { NgIf, NgClass, NgFor, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'app-full-order',
  templateUrl: './full-order.component.html',
  styleUrls: ['./full-order.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, NgClass, NgFor, ErrorModalComponent, CurrencyPipe, DatePipe, CustomCurrencyPipe]
})
export class FullOrderComponent implements OnInit, OnDestroy {
  isEditingFromFullOrder = false;
  shippingAddress: string;
  shipping_address: string[];
  billing_address: string[];
  selectedOrder: models.Order;
  userDiscount: number;
  orderTotal: number;
  subtotal: number;
  terms: string;
  tax: number;
  showCancelModal = false;
  showModal = false;
  cancelError = false;
  editOrderPopUpMessage: string;
  cancelOrderPopUpMessage: string;
  animateOut = false;
  animateIn = false;
  docEntry = -1;
  upDateOrderConfirmation = false;
  errorModal: models.IBuyerErrorModal = {
    bodyText: '',
    title: 'Server Error',
    isShown: false
  };
  order: any;
  isSuper = false;
  showChargeOrder = false;
  showChargedOrder = false;
  SalesOrderStatus = models.SalesOrderStatus;

  constructor(
    public ordersService: OrdersService,
    public topService: TopService,
    public userService: UserService,
    private router: Router,
    private pubSubService: PubSubService,
    private route: ActivatedRoute,
    public sessionService: SessionService,
    private sharedService: SharedService
  ) {
    this.ordersService.filterAndMapSalesOrderStatus();
  }

  ngOnInit(): void {
    const order: models.Order = this.route.snapshot.data['order'];
    if (this.topService.isMobile) {
      this.openPdf(null, order.docEntry);
      this.router.navigate(['user/orders']);
      return;
    }
    this.isSuper = this.sessionService.userRole === 'SUPER' ? true : false;
    this.topService.trendingNavVisible = true;
    this.sharedService.isLogoutPage = false;
    this.pubSubService.sharedSubject.subscribe(myEvent => {
      if (myEvent.name === SharedSource.orderSearch) {
        this.getData(myEvent.data);
      }
    });
    this.getData(order);
  }

  getData(order: models.Order): void {
    const docEntry = order.docEntry;
    this.selectedOrder = order;
    this.userDiscount = this.selectedOrder.orderDiscountAmount;
    this.orderTotal = this.selectedOrder.orderTotal;
    this.subtotal = this.selectedOrder.orderTotal;
    this.tax = this.selectedOrder.tax;
    this.shippingAddress = this.selectedOrder.shippingAddress;

    if (this.selectedOrder) {
      this.selectedOrder.comments = this.selectedOrder.comments
        ? this.selectedOrder.comments.replace(/[\r\n]+|[\r\n]\s+\S*/g, ' | ')
        : ''
    }

    this.pubSubService.sharedSubject.subscribe(
      mySharedValues => {
        if (mySharedValues.name === SharedSource.changeCustomer) {
          this.router.navigate(['buyer/home']);
        }
      },
      error => {
      }
    );

    if (!docEntry) {
      this.router.navigate(['/user/orders']);
    } else {
      if (this.selectedOrder.billingAddress) {
        this.shipping_address = this.selectedOrder.billingAddress.split('\r');
      }
      if (this.selectedOrder.shippingAddress) {
        this.billing_address = this.selectedOrder.shippingAddress.split('\r');
      }
    }

    if (this.isEditingFromFullOrder) {
      this.userService.getProfile().subscribe(
        (data) => {
          this.userService.discount = data.discount;
          this.userService.companyName = data.companyName;
          this.userService.bpCode = data.bpCode;
          this.userService.orderBankInfo = data.beneficiaryBank;
        },
        (err) => {
          this.sharedService.handleBuyerHttpError(err, this.errorModal);
        });
    }
  }

  enteringEditingMode(): boolean {
    return this.isEditingFromFullOrder = true;
  }

  totalAmount(): number {
    let total = 0;
    for (let i = 0; i < this.selectedOrder.orderDetails.length; i++) {
      {
        // tslint:disable-next-line:max-line-length
        total = total + (this.selectedOrder.orderDetails[i].quantity * this.selectedOrder.orderDetails[i].unitPrice);
      }
    }
    return total;
  }

  calculateOrderTotalWithFreight(): number {
    return this.orderTotal + this.selectedOrder.shippingCost;
  }

  openPdf(event: Event, docEntry: number): void {
    this.ordersService.openPdf(event, docEntry);
  }

  updateOrderConfirmDialog(event, order): void {
    this.ordersService.selected_doc_number = order.docNumber;
    this.ordersService.selected_order_number = order.docEntry;
    this.ordersService.handleFirstOrderLoadRequired = true;
    this.openModal();
    this.sessionService.selectedOrderNumber = this.ordersService.selected_order_number.toString();
    this.sessionService.selectedDocNumber = this.ordersService.selected_doc_number.toString();
    this.upDateOrderConfirmation = true;
  }

  closeModal(): void {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.showModal = false;
      this.animateOut = false;
    }, 400);
  }

  openModal(): void {
    this.showModal = true;
    this.animateIn = true;
  }

  cancelConfirm(order: models.Order): void {
    this.docEntry = order.docEntry;
    this.showCancelModal = true;
    this.animateIn = true;
    this.animateOut = false;
    this.cancelError = false;
  }

  cancelOrderYes(): void {
    this.topService.loading = true;
    this.ordersService.cancelOrder(this.docEntry).subscribe(response => {
      this.topService.searchString = '';
      this.animateIn = false;
      this.showCancelModal = false;
      this.animateOut = true;
      this.topService.loading = false;
      this.router.navigate(['/user/orders']);
    },
      err => {
        this.topService.loading = false;
        this.topService.searchString = '';
        this.cancelError = true;
      }
    );
  }

  closeCancelConfirm(): void {
    this.animateIn = false;
    this.animateOut = true;
    setTimeout(() => {
      this.showCancelModal = false;
      this.animateOut = false;
    }, 400);
  }

  popUpTextMessages(): string {
    if (this.showModal) {
      return this.editOrderPopUpMessage = 'To edit an order, your shopping cart will be cleared.';
    } else if (this.showCancelModal) {
      if (this.cancelError) {
        return this.cancelOrderPopUpMessage = 'You cannot cancel this order';
      } else {
        return this.cancelOrderPopUpMessage = 'Are you sure you want to CANCEL this order?';
      }
    }
  }

  gotoShopping(): void {
    this.router.navigate(['buyer/home']);
  }

  editOrder(docEntry: number): void {
    if (!this.topService.loading) {
      this.enteringEditingMode();
      this.topService.loading = true;
      const selectedCustCode = this.userService.bpCode;

      if (selectedCustCode !== this.selectedOrder.cardCode) {
        this.ordersService.selected_doc_number = this.selectedOrder.docNumber;
        this.ordersService.selected_order_number = this.selectedOrder.docEntry;
        this.ordersService.handleFirstOrderLoadRequired = true;
        this.sessionService.selectedOrderNumber = this.ordersService.selected_order_number.toString();
        this.sessionService.selectedDocNumber = this.ordersService.selected_doc_number.toString();
        this.userService.sendSelectedCustomerToAPI(this.selectedOrder.cardCode).subscribe(
          () => {
            this.sessionService.isCustomerSelected = 'YES';
            this.sessionService.selectedCustomer = selectedCustCode;
            this.topService.isCustomerSelected = true;
            this.userService.getProfile().subscribe(
              (profile) => {
                this.userService.currentCustomer = `${profile.bpCode} - ${profile.companyName}`;
                if (profile.discount !== null) {
                  this.userService.discount = profile.discount;
                } else {
                  this.userService.discount = 0;
                }
                this.topService.hasOpenOrders = profile.hasOpenOrders;
                this.userService.companyName = profile.companyName;
                this.userService.bpCode = profile.bpCode;
                this.userService.billing = profile.billingAddresses;
                if (profile.billingAddresses.length > 0) {
                  this.userService.billingDefault = profile.billingAddresses.find(a => a.isDefault);
                  if (!this.userService.billingDefault) {
                    this.userService.billingDefault = profile.billingAddresses[0];
                  }
                } else {
                  this.userService.resetBilling();
                }
                if (profile.shippingAddresses.length > 0) {
                  this.userService.shippingDefault = profile.shippingAddresses.find(a => a.isDefault);
                  if (!this.userService.billingDefault) {
                    this.userService.billingDefault = profile.billingAddresses[0];
                  }
                } else {
                  this.userService.resetShipping();
                }
                this.userService.shipping = profile.shippingAddresses;
                this.userService.terms = profile.terms;
                this.userService.orderBankInfo = profile.beneficiaryBank;
                this.updateOrder(this.selectedOrder.docEntry);
              },
              (err) => {
                this.topService.loading = false;
                this.sharedService.handleBuyerHttpError(err, this.errorModal);
              });
          },
          (err) => {
            this.topService.loading = false;
            this.sharedService.handleBuyerHttpError(err, this.errorModal);
          });
      } else {
        this.closeModal();
        this.updateOrder(this.selectedOrder.docEntry);
      }
    }
  }

  updateOrder(docEntry): void {
    this.ordersService.updateOrder(docEntry).subscribe(
      () => {
        this.router.navigate(['cart'], { queryParams: { updatingOrder: 'Y' } });
      }, err => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.errorModal);
      }
    );
  }

  canEditOrder(): boolean {
    return (this.selectedOrder.orderStatus === OrderStatusEnum.offerAccepted && this.userService.getRole() === 'SUPER' && !this.selectedOrder.beingUpdated) ||
      (this.selectedOrder.orderStatus === OrderStatusEnum.open &&
        (this.userService.getRole() === 'SALES' || this.userService.getRole() === 'SUPER') && !this.selectedOrder.beingUpdated) ||
      (this.selectedOrder.orderStatus === OrderStatusEnum.paid && this.selectedOrder.payment.status === 'Authorize' && this.userService.getRole() === 'SUPER');
  }

  canSplitOrCancelOrder(): boolean {
    return this.userService.getRole() === 'SUPER' &&
      [OrderStatusEnum.open, OrderStatusEnum.offerAccepted].includes(this.selectedOrder.orderStatus as OrderStatusEnum);
  }

  isShowModalOrShowCancelModal(): boolean {
    return this.showModal || this.showCancelModal;
  }

  showError(errorModal: models.IBuyerErrorModal): void {
    this.errorModal = errorModal;
  }

  hideError(): void {
    this.errorModal.isShown = false;
  }

  ngOnDestroy() {
    this.topService.trendingNavVisible = false;
  }

  splitOrder(order: models.Order): void {
    this.topService.loading = true;
    this.ordersService.getOrderDetails(order.docEntry.toString()).subscribe(
      (orderData: models.IOrder) => {
        this.topService.loading = false;
        this.ordersService.orderChosen = orderData;
        this.router.navigate(['/user/split', order.docEntry]);
      },
      (err) => {
        this.topService.loading = false;
        this.sharedService.handleBuyerHttpError(err, this.ordersService.ordersErrorModal);
      }
    );
  }

  showChargeModal() {
    this.showChargeOrder = true;
  }

  closeChargedModal() {
    this.showChargedOrder = false;
  }

  chargeOrder(): void {
    if (this.isSuper) {
      this.topService.loading = true;
      this.ordersService.chargeOrder(this.selectedOrder.payment.id, this.selectedOrder.orderTotal).subscribe(
        (res) => {
          this.topService.loading = false;
          this.showChargeOrder = false;
          if (res.error) {
            const modalData = {
              bodyText: res.error.message,
              isShown: true,
            };
            this.showError(modalData);
            return;
          }
          this.showChargedOrder = true;
          this.selectedOrder.payment.status = 'Applied';
        });
    }
  }

  getStatusProgressWidth(): string {
    return this.ordersService.getStatusProgressWidth(this.selectedOrder);
  }

}
