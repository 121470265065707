import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ItemsService } from '../../shared/services/items.service';
import { TopService } from '../../shared/services/top.service';
import { OrdersService } from '../order-history/orders/orders.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Subscription } from 'rxjs';
import { PageErrorService } from '../../page-error/page-error.service';
import * as _ from 'lodash';
import { SharedService } from '../../shared/shared.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['../cart/cart.component.scss', './thank-you.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class ThankYouComponent implements OnInit, OnDestroy {
  orderId: number;
  private sub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public topService: TopService,
    public ordersService: OrdersService,
    public googleAnalyticsService: GoogleAnalyticsService,
    public itemsService: ItemsService,
    public pageErrorService: PageErrorService,
    public sharedService: SharedService) { }

  ngOnInit() {
    this.topService.loading = false;
    this.topService.trendingNavVisible = true;
    this.sub = this.route.params.subscribe(
      (params) => {
        this.orderId = +params['orderId'];
      },
      (err) => {
        this.pageErrorService.errorCode = err.status;
        this.pageErrorService.errorMessage = err.error_description;
        this.router.navigate(['page-error']);
      });
  }

  ngOnDestroy() {
    this.topService.trendingNavVisible = false;
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  gotoHistory(event: MouseEvent): void {
    if ( _.isEmpty(this.ordersService.orderChosen) ) {
      const urlArray = location.pathname.split('/');
      const currentOrder = ( urlArray.length === 4 ) ? urlArray[3] : undefined;
      if ( currentOrder ) {
        this.topService.loading = true;
        this.ordersService.getOrderDetails(String(+currentOrder - 9999))
        .subscribe(order => {
          this.topService.loading = false;
          this.ordersService.orderChosen = order;
          if ( this.topService.isMobile ) {
            this.ordersService.openPdf(event, this.ordersService.orderChosen.docEntry);
          } else {
            this.router.navigate(['/buyer/full-order', this.ordersService.orderChosen.docEntry]);
          }
        },
        err => {
          console.error('Error getting order detail', err);
          this.topService.loading = false;
        });
      }
    } else {
      if ( this.topService.isMobile ) {
        this.ordersService.openPdf(event, this.ordersService.orderChosen.docEntry);
      } else {
        this.ordersService.selectedOrder = null;
        this.router.navigate(['/buyer/full-order', this.ordersService.orderChosen.docEntry]);
      }
    }
  }

  gotoCatalog(): void {
    // this.itemsService.itemsRequest = {
    //   savedFilter: 'default'
    // };
    // this.itemsService.getItems();
    this.router.navigate(['buyer/home']);
  }
}
